import React from "react";
import { useWeb3Store } from "../hooks/useWeb3";

import { EthereumProviderError } from "eth-rpc-errors";

/**
 * Connect to wallet via MetaMask. You should *only* initiate a connection
 * request in response to direct user action, such as clicking a button. You
 * should *always* disable the "connect" button while the connection request is
 * pending. You should *never* initiate a connection request on page load.
 */
export async function connect() {
  if (!window.ethereum?.request) return;

  try {
    // Intentionally not using `ethers` here so we don't do unneccessary global
    // state updates and cause extra renders
    await window.ethereum.request({
      method: "eth_requestAccounts",
    });
  } catch (error) {
    if (error instanceof EthereumProviderError && error.code === 4001) {
      // EIP-1193 userRejectedRequest error
      // If this happens, the user rejected the connection request.
      console.error("Please connect to MetaMask.");
    } else {
      console.error(error);
    }
  }
}

export function ConnectButton() {
  const account = useWeb3Store((state) => state.account);
  const hasProvider = useWeb3Store((state) => state.hasProvider);

  if (!hasProvider) {
    return (
      <a
        href="https://metamask.io"
        target="_blank"
        rel="noopener noreferrer"
        className="button"
      >
        Install MetaMask
      </a>
    );
  }

  return !account ? (
    <div>
      <button className="button" onClick={connect}>
        Connect to MetaMask
      </button>
      <div className="disclaimer">
        Connect to get KYC-verified. If you've previously been verified with
        Blockpass, you won't need to re-apply.
      </div>
    </div>
  ) : (
    <div>
      Connected as <span>{account}</span>
    </div>
  );
}
