import React from "react";
import { useWeb3 } from "./hooks/useWeb3";
import { Background } from "./components/Background";
import { KYC } from "./components/KYC";
import { Sponsors } from "./components/Sponsors";

function App() {
  // Initialize Web3
  useWeb3();

  return (
    <main>
      <Background />
      <KYC />
      <Sponsors />
    </main>
  );
}

export default App;
