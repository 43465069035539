import React from "react";
import useSWR from "swr";
import { useWeb3Store } from "../../hooks/useWeb3";
import { ConnectButton } from "../ConnectButton";
import { KYCStatus } from "./KYCStatus";
import { KYCError } from "./KYCError";

const DEFAULT_API_ENDPOINT = "https://kyc-api.animocabrands.com/v1.0/profile";

async function fetcher(url) {
  const response = await fetch(url);
  return response.json();
}

export function KYC() {
  const walletAddress = useWeb3Store((state) => state.account);

  const { data, error } = useSWR(
    walletAddress
      ? `${DEFAULT_API_ENDPOINT}/?walletAddress=${walletAddress}`
      : null,
    fetcher
  );

  const status = data && data.status ? data.status : "LOADING";

  if (!walletAddress) {
    return (
      <div className="kyc">
        <ConnectButton />
      </div>
    );
  }

  if (error) {
    return (
      <div className="kyc">
        <KYCError walletAddress={walletAddress} />
      </div>
    );
  }

  return (
    <div className="kyc">
      <div className="kyc-block">
        <div className="kyc-label">Account</div>
        <div className="kyc-body">{walletAddress}</div>
      </div>
      <div className="kyc-block">
        <KYCStatus status={status} />
      </div>
    </div>
  );
}
