import React from "react";
import cn from "classnames";

// const KYC_SUBMISSION_URL = "https://verify-with.blockpass.org/?clientId=yuga_labs_x_animoca_brands_08b88&serviceName=Yuga%20Labs%20x%20Animoca%20Brands&env=prod";

const STATUS_TEXTS = {
  APPROVED: `Approved!`,
  DECLINED: `Declined`,
  IN_REVIEW: `Pending...`,
  LOADING: `Loading...`,
  NOT_FOUND: `Not registered`
};

export function KYCStatus({ status }) {
  // if (status === "NOT_FOUND") {
  //   // Register button
  //   return (
  //     <a target="_blank" rel="noopener noreferrer" href={KYC_SUBMISSION_URL}>
  //       <div className="button">Register</div>
  //     </a>
  //   );
  // }

  return (
    <>
      <div className="kyc-label">
        <div>Status</div>
        <div className={cn("status", `status--${status.toLowerCase()}`)}></div>
      </div>
      <div className="kyc-body">{STATUS_TEXTS[status]}</div>
    </>
  );
}
