import React from "react";
import cn from "classnames";
import { useWeb3Store } from "../hooks/useWeb3";

export function Background() {
  const account = useWeb3Store((state) => state.account);

  return (
    <div className={cn("background", account && "background--blurred")}>
      <video loop autoPlay muted playsInline poster="/assets/dock.jpg">
        <source src="/assets/video/dock.webm" type="video/webm" />
        <source src="/assets/video/dock.mp4" type="video/mp4" />
      </video>
      <div className="blur" />
    </div>
  );
}
