import React from "react";

const SPONSORS = [
  ["yuga-labs.svg", 80],
  ["animoca.svg", 75],
  ["apecoin.svg", 90],
];

export function Sponsors() {
  return (
    <div className="sponsors">
      <div className="sponsors-list">
        {SPONSORS.map(([filename, width], i) => {
          return (
            <div className="sponsor" key={i}>
              <img src={`/assets/sponsors/${filename}`} alt={filename} width={width} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
