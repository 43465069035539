import { useEffect } from "react";
import { providers } from "ethers";
import createStore from "zustand";
import { combine } from "zustand/middleware";

const initialState = {
  /** Default Ethereum provider */
  provider: null,
  /** Current connected account */
  account: "",
  /** User is connected to via provider */
  isConnected: false,
  /** User has provider available */
  hasProvider: false,
};

/**
 * Hook storing information such as account and chainId. This should be used in
 * components that need to read wallet state. Unlike a React Provider pattern,
 * this won't rerender the entire app when state is updated.
 */
export const useWeb3Store = createStore(
  combine(initialState, (set) => ({
    initializeAccount: (state) => set({ ...state }),
  }))
);

/**
 * Fetches accounts and chain ID from provider (MetaMask). This hook calls a
 * useEffect hook that populates the {@link useWeb3Store} hook with data. This is a
 * top-level hook that should be included in the root component (App), or only
 * on pages that need web3 account data.
 */
export const useWeb3 = () => {
  const initializeAccount = useWeb3Store((state) => state.initializeAccount);

  // Attempt to connect to provider (MetaMask) when the app loads
  useEffect(() => {
    if (!window.ethereum) return;

    const provider = new providers.Web3Provider(window.ethereum);

    async function fetchAccount() {
      try {
        // This will likely only have one address, which is all we need.
        const [account] = await provider.listAccounts();

        initializeAccount({
          provider,
          account: account || "",
          isConnected: Boolean(account),
          hasProvider: Boolean(provider),
        });
      } catch (error) {
        console.error(error);
      }
    }

    // We recommend reloading the page, unless you must do otherwise.
    function reloadPage() {
      console.warn("Detected change, reloading...");
      window.location.reload();
    }

    // Get provider on mount
    void fetchAccount();

    // Setup events
    window.ethereum.on("accountsChanged", fetchAccount);
    window.ethereum.on("chainChanged", reloadPage);
    window.ethereum.on("disconnect", reloadPage);

    return () => {
      window.ethereum.removeListener("accountsChanged", fetchAccount);
      window.ethereum.removeListener("chainChanged", reloadPage);
      window.ethereum.removeListener("disconnect", reloadPage);
    };
  }, [initializeAccount]);
};
