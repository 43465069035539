import React from "react";

export function KYCError({ walletAddress, error }) {
  console.error(error);

  return (
    <div className="kyc">
      <div className="kyc-block">
        <div className="kyc-label">Error</div>
        <div className="kyc-body">
          {error.message ? (
            <span>{`${walletAddress} has ${error.message} error.`}</span>
          ) : (
            <span>{`${walletAddress} has ${error}`}</span>
          )}
        </div>
      </div>
    </div>
  );
}
